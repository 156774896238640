body {
  margin: 0;
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiInputAdornment-root, .MuiSvgIcon-root, .MuiTextField-root, .MuiCircularProgress-root {
  color: #920382
}

code {
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
