.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="date"]::-webkit-calendar-picker-indicator { background: none; display:none; }

input:focus, textarea:focus, select:focus{
  outline: none;
}

input[type="time"]{
-webkit-appearance: textfield;
-moz-appearance: textfield;
height: 10px;
min-width: 100%;
}

button {
  font-family: 'Rubik';
}

textarea {
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: #000;
  font-style: normal;
  line-height: 22px;
}

input{
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select{
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}

.MuiPickersToolbarText-toolbarBtnSelected{
  text-transform: capitalize;
}

.MuiPickersSlideTransition-transitionContainer > * {
  text-transform: capitalize;
}
